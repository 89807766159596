import { inject, Injectable } from '@angular/core';
import {
    foundValidUserSession,
    generateTOTPSecret,
    getMultiFactorInfo,
} from '@frontend/data-access/user/authentication';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { mfaPageLoaded } from '../client-info/client-info.actions';

@Injectable()
export class SupportAuthenticationEffects {
    private readonly actions$ = inject(Actions);

    mfaPageLoaded$ = createEffect(() => {
        return zip([this.actions$.pipe(ofType(mfaPageLoaded)), this.actions$.pipe(ofType(foundValidUserSession))]).pipe(
            map(() => {
                return generateTOTPSecret();
            }),
        );
    });

    getEnrolledMultiFactorInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foundValidUserSession),
            map(() => {
                return getMultiFactorInfo();
            }),
        );
    });
}
