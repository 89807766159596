import { Routes } from '@angular/router';
import { AuthGuard } from '@frontend/data-access/user/authentication';
import {
    AgentChatPageComponent,
    LoginPageComponent,
    MfaEntryPageComponent,
    MfaPageComponent,
    ResetPasswordPageComponent,
} from '@frontend/zigzag-support';

export const expertSupportPortalRoutes: Routes = [
    {
        path: '',
        redirectTo: 'agent-chat',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        component: LoginPageComponent,
        title: 'Agent Portal - Login',
    },
    {
        path: 'reset-password',
        component: ResetPasswordPageComponent,
        title: 'Agent Portal - Reset Password',
    },
    {
        path: 'agent-chat',
        component: AgentChatPageComponent,
        title: 'Agent Portal - Chat',
        canActivate: [AuthGuard],
    },
    {
        path: 'mfa-setup',
        component: MfaPageComponent,
        title: 'Agent Portal - MFA Setup',
    },
    {
        path: 'mfa-entry',
        component: MfaEntryPageComponent,
        title: 'Agent Portal - MFA Entry',
    },
];
