import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, RouteReuseStrategy } from '@angular/router';
import { BASE_ENVIRONMENT_CONFIG, ProjectEnvironment, WINDOW } from '@frontend/configuration';
import {
    AnalyticsEffects,
    MIXPANEL_FRONTEND_CONFIG,
    MixpanelPeopleServiceProvider,
    MixpanelServiceProvider,
} from '@frontend/data-access/analytics';
import {
    CHAT_CONFIG,
    ChatApiEffects,
    ChatEffects,
    chatFeature,
    ChatService,
    chatServiceFactory,
} from '@frontend/data-access/chat';
import { ZigzagFrontendDataAccessSentryModule } from '@frontend/data-access/sentry';
import { TimeEffects, timeFeature } from '@frontend/data-access/time';
import {
    AUTHENTICATION_CONFIG,
    AuthenticationEffects,
    authenticationFeature,
    AuthenticationService,
    authenticationServiceFactory,
} from '@frontend/data-access/user/authentication';
import { ChatTagsEffects, chatTagsFeature, ChatTagsService } from '@frontend/data-access/user/chat-tags';
import {
    AgentChatPageEffects,
    AuthenticationInterceptor,
    ClientInfoEffects,
    ProfileEffects,
    STREAM_CHAT_AGENT_TOKEN,
    SupportAuthenticationEffects,
    SupportChatEffects,
    SupportChatTagsEffects,
    supportPortalClientInfoFeature,
    supportPortalProfileInfoFeature,
    SupportRouterEffects,
} from '@frontend/zigzag-support';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { StreamChatModule, StreamTextareaModule } from 'stream-chat-angular';
import { AppComponent } from './app/app.component';
import { expertSupportPortalRoutes } from './app/expert-support-portal.routes';
import { environment } from './environments/environment';
import {
    BreedGroupEffects,
    breedGroupFeature,
    ChatTileEffects,
    chatTileTopicsFeature,
    CourseEffects,
    coursesFeature,
    DailyBitesEffects,
    dailyBitesFeature,
    DevelopmentalWindowEffects,
    developmentalWindowsFeature,
    SeasonalTopicEffects,
    seasonalTopicsFeature,
    StepEffects,
    stepsFeature,
    TopicEffects,
    topicsFeature,
} from '@frontend/data-access/contentful';
import { Mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel/ngx';
import { RouterEffects } from '@frontend/data-access/router';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';

if (environment.baseEnvironmentConfig.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideFirebaseApp(() =>
            initializeApp({
                apiKey: environment.firebaseConfig.apiKey,
                authDomain: environment.firebaseConfig.authDomain,
                storageBucket: environment.firebaseConfig.storageBucket,
                messagingSenderId: environment.firebaseConfig.messagingSenderId,
                appId: environment.firebaseConfig.appId,
                projectId: environment.firebaseConfig.projectId,
                databaseURL: environment.firebaseConfig.databaseURL ?? '',
            }),
        ),
        provideAuth(() => getAuth()),
        importProvidersFrom(
            StreamChatModule,
            StreamTextareaModule,
            TranslateModule.forRoot(),
            StreamTextareaModule,
            ReactiveFormsModule,
            HttpClientModule,
            FormsModule,
            ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
                ? [ZigzagFrontendDataAccessSentryModule]
                : []),
        ),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideIonicAngular({ mode: 'ios' }),
        provideStore(
            {},
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                },
            },
        ),
        provideState(supportPortalClientInfoFeature),
        provideState(supportPortalProfileInfoFeature),
        provideState(breedGroupFeature),
        provideState(coursesFeature),
        provideState(dailyBitesFeature),
        provideState(developmentalWindowsFeature),
        provideState(stepsFeature),
        provideState(topicsFeature),
        provideState(chatTileTopicsFeature),
        provideState(seasonalTopicsFeature),
        provideState(chatFeature),
        provideState(timeFeature),
        provideState(authenticationFeature),
        provideState('router', routerReducer),
        provideState(chatTagsFeature),
        provideEffects([
            SupportChatEffects,
            SupportRouterEffects,
            ProfileEffects,
            AgentChatPageEffects,
            ClientInfoEffects,
            SupportAuthenticationEffects,
            SupportChatTagsEffects,
            BreedGroupEffects,
            CourseEffects,
            DailyBitesEffects,
            DevelopmentalWindowEffects,
            StepEffects,
            TopicEffects,
            ChatTileEffects,
            SeasonalTopicEffects,
            ChatEffects,
            ChatApiEffects,
            TimeEffects,
            AnalyticsEffects,
            AuthenticationEffects,
            RouterEffects,
            ChatTagsEffects,
        ]),
        ChatTagsService,
        provideRouterStore(),
        ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.DEVELOPMENT
            ? [provideStoreDevtools({ connectInZone: true })]
            : []),
        provideRouter(expertSupportPortalRoutes),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        { provide: AUTHENTICATION_CONFIG, useValue: environment.firebaseConfig },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: BASE_ENVIRONMENT_CONFIG, useValue: environment.baseEnvironmentConfig },
        { provide: WINDOW, useValue: window },
        {
            provide: CHAT_CONFIG,
            useValue: {
                apiKey: environment.streamChatApiKey,
            },
        },
        {
            provide: STREAM_CHAT_AGENT_TOKEN,
            useValue: environment.streamChatAgentToken,
        },
        { provide: MIXPANEL_FRONTEND_CONFIG, useValue: environment.mixpanelConfig },
        ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
            ? [
                  {
                      provide: ErrorHandler,
                      useValue: Sentry.createErrorHandler({
                          showDialog: false,
                      }),
                  },
                  {
                      provide: Sentry.TraceService,
                      deps: [Router],
                  },
                  provideAppInitializer(() => {
                      inject(Sentry.TraceService);
                  }),
              ]
            : []),
        Mixpanel,
        MixpanelPeople,
        MixpanelServiceProvider,
        MixpanelPeopleServiceProvider,
        {
            provide: AuthenticationService,
            useFactory: authenticationServiceFactory,
            deps: [AUTHENTICATION_CONFIG],
        },
        {
            provide: ChatService,
            useFactory: chatServiceFactory,
            deps: [CHAT_CONFIG],
        },
    ],
}).catch((err) => console.error('error:', err));
