import { Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { authenticationFeature, logout } from '@frontend/data-access/user/authentication';
import { QRCodeModule } from 'angularx-qrcode';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { routeTo } from '@frontend/data-access/router';
import { mfaPageLoaded } from '../data-access/client-info/client-info.actions';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'app-mfa-setup',
    templateUrl: './mfa.page.html',
    styleUrl: './mfa.page.scss',
    standalone: true,
    imports: [ReactiveFormsModule, IonContent, IonButton, QRCodeModule, InvalidInputErrorMessageComponent],
})
export class MfaPageComponent implements OnInit {
    private readonly store = inject(Store);
    protected readonly Color = Color;

    public totpSecret = this.store.selectSignal(authenticationFeature.selectTotpSecret);
    public totpLoading = this.store.selectSignal(authenticationFeature.selectTotpSecretLoading);
    public totpError = this.store.selectSignal(authenticationFeature.selectTotpSecretError);

    ngOnInit(): void {
        this.store.dispatch(mfaPageLoaded());
    }

    copyToClipboard(): void {
        navigator.clipboard.writeText(this.totpSecret()?.key ?? '');
    }

    onClick(): void {
        void this.store.dispatch(routeTo({ commands: ['mfa-entry'] }));
    }

    public logoutAgent() {
        this.store.dispatch(logout());
    }
}
