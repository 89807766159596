import { Component, EventEmitter, input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { GenericChipComponent } from '../generic-chip/generic-chip.component';
import { NgClass } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-voucher-large',
    templateUrl: './voucher-large.component.html',
    styleUrl: './voucher-large.component.scss',
    imports: [IonicModule, GenericChipComponent, NgClass],
})
export class VoucherLargeComponent {
    @Output() handleButtonClick = new EventEmitter<void>();

    public heading = input.required<string>();
    public subHeading = input.required<string>();
    public buttonColour = input.required<string>();
    public buttonText = input.required<string>();
    public image1Url = input.required<string>();
    public image2Url = input.required<string>();
    public image1Alt = input.required<string>();
    public image2Alt = input.required<string>();
    public locked = input.required<boolean>();

    public handleClick(): void {
        this.handleButtonClick.emit();
    }
}
