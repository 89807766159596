import { Color } from '@shared/utils/typescript';
import { Asset, Entry } from 'contentful';
import { ContentfulTopic } from './contentful-topic.model';

export interface ContentfulSeasonalTopic {
    id: string;
    title: string;
    image: Asset;
    backgroundColour: Color;
    topic: Entry<ContentfulTopic>;
    startDate: string;
    endDate: string;
}
