import { Asset, RichTextContent } from 'contentful';

export interface ContentfulAuthor {
    authorName: string;
    authorImage: Asset;
    authorBio: RichTextContent;
    authorCertifications: string[];
    authorOrganisation: string;
    authorCircleImage: Asset;
}
