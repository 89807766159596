export interface DomainStepProgressDto {
    id: string;
    userId: string;
    dogId?: string;
    stepId: string;
    contentId: number;
    progressTypeId: string;
    timestamp: number;
    rating?: number;
}
