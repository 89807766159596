import { createRequestReducer, RequestState } from '@frontend/utility/request-reducer';
import { InternalGeoLocationDto } from '@shared/internal-api-interface';
import { Action, combineReducers } from '@ngrx/store';
import { getGeoLocation, getGeoLocationFailure, getGeoLocationSuccess } from './geo-location.actions';

export const geoLocationFeatureKey = 'geoLocationState';

export interface GeoLocationState {
    geoLocation: RequestState<InternalGeoLocationDto>;
}

const reducer = combineReducers({
    geoLocation: createRequestReducer<InternalGeoLocationDto>(
        [[getGeoLocation], [getGeoLocationSuccess], [getGeoLocationFailure]],
        true,
    ),
});

export const geoLocationReducer = (state: GeoLocationState | undefined, action: Action): GeoLocationState =>
    reducer(state, action);
