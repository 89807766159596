import { Color } from '@shared/utils/typescript';
import { TileType } from '../enums/tile-type.enum';

export interface SeasonalTopicsState {
    seasonalTopics: SeasonalTopic[];
}

export interface SeasonalTopic {
    id: string;
    title: string;
    image: string;
    backgroundColour: Color;
    topic: string;
    startDate: string;
    endDate: string;
    tileType?: TileType;
}
