import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { logout } from '@frontend/data-access/user/authentication';
import { IonButton, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-agent-header',
    templateUrl: './agent-header.component.html',
    styleUrl: './agent-header.component.scss',
    imports: [IonHeader, IonToolbar, IonButton],
})
export class AgentHeaderComponent {
    private readonly store = inject(Store);

    // TODO: NBSon - use signals
    @Input() isMenuOpen = false;

    @Output() toggleMenu = new EventEmitter<boolean>();

    public logoutAgent() {
        this.store.dispatch(logout());
    }
}
