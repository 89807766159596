import { inject, Injectable } from '@angular/core';
import {
    AuthenticationService,
    enrollUserTOTPSuccess,
    foundValidUserSession,
    logoutSuccess,
} from '@frontend/data-access/user/authentication';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';
import { forceAppRefresh, routeTo } from '@frontend/data-access/router';
import { filter, zip } from 'rxjs';
import { getProfileSuccess } from '../profile/profile.actions';
import { getAllChatTags } from '@frontend/data-access/user/chat-tags';

@Injectable()
export class SupportRouterEffects {
    private readonly actions$ = inject(Actions);
    private readonly authenticationService = inject(AuthenticationService);

    redirectToRoot$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutSuccess),
            map(() => {
                return forceAppRefresh();
            }),
        );
    });

    routeToHomeAfterEnroll$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(enrollUserTOTPSuccess),
            concatMap(() => {
                return [routeTo({ commands: ['home'] }), getAllChatTags()];
            }),
        );
    });

    routeToHome$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(foundValidUserSession)),
            this.actions$.pipe(ofType(getProfileSuccess)),
        ]).pipe(
            concatMap(() => this.authenticationService.isMfaEnrolled$),
            filter((isMfaEnabled) => isMfaEnabled),
            concatMap(() => {
                return [routeTo({ commands: ['agent-chat'] }), getAllChatTags()];
            }),
        );
    });

    routeToEnroll$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(foundValidUserSession)),
            this.actions$.pipe(ofType(getProfileSuccess)),
        ]).pipe(
            concatMap(() => this.authenticationService.isMfaEnrolled$),
            filter((isMfaEnabled) => {
                return !isMfaEnabled;
            }),
            map(() => {
                return routeTo({ commands: ['mfa-setup'] });
            }),
        );
    });
}
