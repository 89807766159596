<ion-content class="page-ion-content ion-text-center" [fullscreen]="true" [color]="Color.Harley">
    <div class="mfa-content-container">
        <main class="mfa-content page-content">
            <section>
                <h1>Turn on 2-Step Verification</h1>
                <p>
                    Scan the QR code below using your authenticator app, then enter the verification code to complete
                    the setup.
                </p>

                <qrcode [qrdata]="totpSecret()?.url ?? ''" [width]="256" />

                <p>or enter the code manually</p>

                @if (totpError()?.code ?? '') {
                <ui-invalid-input-error-message class="mfa-content__error" [errorMessage]="totpError()?.code ?? ''" />
                }

                <div class="mfa-content__secret-key-group">
                    <p class="mfa-content__code as-div" data-test="secret-key">{{ totpSecret()?.key ?? '' }}</p>
                    <ion-button
                        class="mfa-content__button"
                        expand="block"
                        data-test="copy-secret"
                        (click)="copyToClipboard()"
                    >
                        <i class="fa-light fa-copy mfa-content__icon"></i>
                    </ion-button>
                </div>

                <ion-button
                    class="mfa-content__continue-button"
                    size="large"
                    expand="block"
                    data-test="Continue"
                    [color]="Color.Max"
                    [disabled]="totpLoading()"
                    (click)="onClick()"
                >
                    <span>Continue</span>
                </ion-button>

                <ion-button
                    class="mfa-content__logout-button"
                    expand="block"
                    data-test="logout-btn"
                    [color]="Color.Max"
                    [disabled]="totpLoading()"
                    (click)="logoutAgent()"
                    >Log out
                </ion-button>
            </section>
        </main>
    </div>
</ion-content>
