<ion-content class="page-ion-content ion-text-center" [fullscreen]="true" [color]="Color.Harley">
    <div class="mfa-content-container">
        <main class="mfa-content page-content">
            <section>
                <h1 class="mfa-content__title">Verify authentication code</h1>
                <p class="mfa-content__subtitle">Enter the 6-digit code from your authenticator app</p>

                @if (totpError()?.code ?? '') {
                <app-invalid-input-error-message class="mfa-content__error" [errorMessage]="totpError()?.code ?? ''" />
                }

                <form [formGroup]="verificationForm" (ngSubmit)="onClick()">
                    <ion-input
                        class="zz-input mfa-content__code-input"
                        formControlName="code"
                        type="text"
                        data-test="mfa-entry-code-input"
                        [tabindex]="1"
                    />

                    <ion-button
                        class="mfa-content__continue-button"
                        size="large"
                        expand="block"
                        type="submit"
                        data-test="Continue"
                        [color]="Color.Max"
                        [disabled]=""
                    >
                        <span>Continue</span>
                    </ion-button>

                    <ion-button
                        class="mfa-content__logout-button"
                        color="max"
                        expand="block"
                        data-test="logout-btn"
                        slot="end"
                        (click)="logoutAgent()"
                        >Log out
                    </ion-button>
                </form>
            </section>
        </main>
    </div>
</ion-content>
