import { StepProgressType } from '@shared/user-domain';

export interface InternalStepProgressDto {
    id: string;
    userId: string;
    dogId?: string;
    stepId: string;
    contentId: number;
    progressType: StepProgressType;
    timestamp: number;
    rating?: number;
}
