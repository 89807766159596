<section class="voucher-large">
    @if (locked()) {
        <div class="voucher-large__locked-background">
            <div class="voucher-large__locked-icon">
                <i class="fa-solid fa-lock" data-test="voucher-large__locked-icon"></i>
            </div>
        </div>
    }
    <h3 class="voucher-large__heading unset-margin">{{ heading() }}</h3>
    <p class="voucher-large__sub-heading unset-margin">{{ subHeading() }}</p>
    <div class="voucher-large__divider"></div>
    <div class="voucher-large__divider-left"></div>
    <div class="voucher-large__divider-right"></div>
    <img [src]="image1Url()" [alt]="image1Alt()" class="voucher-large__image-1" />
    <img [src]="image2Url()" [alt]="image2Alt()" class="voucher-large__image-2" />
    <ion-button
        [color]="buttonColour()"
        class="voucher-large__button s"
        type="button"
        size="small"
        (click)="handleClick()"
        >{{ buttonText() }}</ion-button
    >
</section>
