import { Component, computed, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { routeTo } from '@frontend/data-access/router';
import {
    authenticationFeature,
    loginWithEmailAndPasswordAndTotp,
    mapAuthenticationError,
} from '@frontend/data-access/user/authentication';
import { InvalidInputErrorMessageComponent } from '@frontend/ui/invalid-input-error-message';
import { IonButton, IonContent, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { FirebaseErrorCode } from '@shared/constants';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrl: './login.page.scss',
    imports: [
        ReactiveFormsModule,
        InvalidInputErrorMessageComponent,
        IonContent,
        IonItem,
        IonInput,
        IonButton,
        IonSpinner,
    ],
})
export class LoginPageComponent {
    private readonly store = inject(Store);
    loginForm = new FormGroup({
        email: new FormControl('', { validators: Validators.required, nonNullable: true }),
        password: new FormControl('', { validators: Validators.required, nonNullable: true }),
        code: new FormControl('', { nonNullable: true }),
    });

    public loginError = this.store.selectSignal(authenticationFeature.selectLoginError);
    public isLoading = this.store.selectSignal(authenticationFeature.selectLoginLoading);

    public loginErrorMessage = computed(() => {
        return mapAuthenticationError(this.loginError()?.code);
    });

    public shouldShowVerificationCodeInput = computed(() => {
        if (!this.loginError()) {
            return false;
        }

        return (
            this.loginError()?.code === FirebaseErrorCode.INVALID_ARGUMENT ||
            this.loginError()?.code === FirebaseErrorCode.INVALID_CODE
        );
    });

    login(): void {
        this.store.dispatch(
            loginWithEmailAndPasswordAndTotp({
                email: this.loginForm.controls.email.value,
                password: this.loginForm.controls.password.value,
                code: this.loginForm.controls.code.value,
            }),
        );
    }

    forgotPassword(): void {
        void this.store.dispatch(routeTo({ url: ['reset-password'] }));
    }
}
