import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { loginWithEmailAndPassword, loginWithEmailAndPasswordAndTotp } from '@frontend/data-access/user/authentication';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { IonButton, IonContent, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { selectLoginPageVm } from './login.page.selectors';
import { routeTo } from '@frontend/data-access/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InvalidInputErrorMessageComponent,
        AsyncPipe,
        IonContent,
        IonItem,
        IonInput,
        IonButton,
        IonSpinner,
    ],
})
export class LoginPageComponent {
    private readonly store = inject(Store);
    loginForm = new FormGroup({
        email: new FormControl('', { validators: Validators.required, nonNullable: true }),
        password: new FormControl('', { validators: Validators.required, nonNullable: true }),
        code: new FormControl('', { nonNullable: true }),
    });

    public vm$ = this.store.select(selectLoginPageVm);

    login(): void {
        if (this.loginForm.controls.code.value) {
            this.store.dispatch(
                loginWithEmailAndPasswordAndTotp({
                    email: this.loginForm.controls.email.value,
                    password: this.loginForm.controls.password.value,
                    code: this.loginForm.controls.code.value,
                }),
            );
        } else {
            this.store.dispatch(
                loginWithEmailAndPassword({
                    email: this.loginForm.controls.email.value,
                    password: this.loginForm.controls.password.value,
                }),
            );
        }
    }

    forgotPassword(): void {
        void this.store.dispatch(routeTo({ commands: ['reset-password'] }));
    }
}
