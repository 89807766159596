import { InternalPostPartnerUserCommand } from '../../../commands/user/revenue-cat/internal-post-partner-user.command';

export class InternalPostPartnerUserCommandMock {
    private defaultValue: InternalPostPartnerUserCommand = {
        userId: 'uuid-1234',
        freeAccessLengthInDays: 365,
    };

    constructor(overrides?: Partial<InternalPostPartnerUserCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPostPartnerUserCommand {
        return this.defaultValue;
    }
}
