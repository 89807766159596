import { inject, Injectable } from '@angular/core';
import { chatFeature } from '@frontend/data-access/chat';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { agentSentMessage } from './page/store/agent-chat.page.actions';

@Injectable()
export class SupportChatEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    trackAgentSentMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(agentSentMessage),
            concatLatestFrom(() => this.store.select(chatFeature.selectActiveChannel)),
            filter(([, activeChannel]) => !!activeChannel),
            map(([{ imagesAttached, videosAttached, audioAttached }, activeChannel]) => {
                return trackEvent({
                    eventName: 'agent-sent-message',
                    eventProperties: {
                        imagesAttached,
                        videosAttached,
                        audioAttached,
                        channelId: activeChannel!.id,
                        channelType: activeChannel!.type,
                    },
                });
            }),
        );
    });
}
