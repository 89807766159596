<ion-content class="page-ion-content ion-padding">
    <app-chat-tags></app-chat-tags>
    <section class="user-information-container">
        @if (!!name()) {
            <p class="unset-margin"><b>Dog Name:</b> {{ name() }}</p>
        }
        @if (!!ownerName()) {
            <p class="unset-margin"><b>Owner Name:</b> {{ ownerName() }}</p>
        }
        @if (breedInfo().breed) {
            <p class="unset-margin"><b>Breed:</b> {{ breedInfo()!.breed }}</p>
        }
        @if (breedInfo().breedType) {
            <p class="unset-margin"><b>Breed Group:</b> {{ breedInfo()!.breedType }}</p>
        }
        @if (!!gender()) {
            <p class="unset-margin"><b>Gender:</b> {{ gender() }}</p>
        }
        @if (!!age() && age() > 0) {
            <p class="unset-margin"><b>Age:</b> {{ age() }} weeks old</p>
        }
        @if (!hasArrived() && !!weeksUntilArrival()) {
            <p class="unset-margin"><b>Weeks until arrival:</b> {{ weeksUntilArrival() }}</p>
        }
        @if (!!countryCode()) {
            <p class="unset-margin"><b>Country Code:</b> {{ countryCode() }}</p>
        }
    </section>

    @if (activeChannel()?.id) {
        <ion-button
            class="end-chat-button"
            color="max"
            expand="block"
            fill="outline"
            (click)="onEndChat(activeChannel()!.id)"
        >
            End Chat
        </ion-button>
    }
</ion-content>
