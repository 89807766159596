import { Component, input } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
    standalone: true,
    selector: 'app-voucher-small',
    templateUrl: './voucher-small.component.html',
    styleUrl: './voucher-small.component.scss',
    imports: [IonicModule],
})
export class VoucherSmallComponent {
    public heading = input.required<string>();
    public subHeading = input.required<string>();
    public text = input.required<string>();
    public imageUrl = input.required<string>();
    public imageAlt = input.required<string>();
}
