import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zip } from 'rxjs';
import { mfaPageLoaded } from '../client-info/client-info.actions';
import { foundValidUserSession, generateTOTPSecret } from '@frontend/data-access/user/authentication';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationEffects {
    private readonly actions$ = inject(Actions);

    mfaPageLoaded$ = createEffect(() => {
        return zip([this.actions$.pipe(ofType(mfaPageLoaded)), this.actions$.pipe(ofType(foundValidUserSession))]).pipe(
            map(() => {
                return generateTOTPSecret();
            }),
        );
    });
}
