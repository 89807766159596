import { InternalGeoLocationDto } from '@shared/internal-api-interface';
import { createAction, props } from '@ngrx/store';

export const getGeoLocation = createAction('[GeoLocation] Get Geo Location');
export const getGeoLocationSuccess = createAction(
    '[GeoLocation] Get Geo Location Success',
    props<{ data: InternalGeoLocationDto }>(),
);
export const getGeoLocationFailure = createAction('[GeoLocation] Get Geo Location Failure', props<{ error: Error }>());
export const getGeoLocationResolved = createAction('[GeoLocation] Get Geo Location Resolved');
