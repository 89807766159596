import { inject, Injectable } from '@angular/core';
import { forceAppRefresh, routeTo } from '@frontend/data-access/router';
import {
    authenticationFeature,
    enrollUserTOTPSuccess,
    getMultiFactorInfoSuccess,
    logoutSuccess,
} from '@frontend/data-access/user/authentication';
import { getAllChatTags } from '@frontend/data-access/user/chat-tags';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, zip } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { getProfileSuccess } from '../profile/profile.actions';

@Injectable()
export class SupportRouterEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    redirectToRoot$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutSuccess),
            map(() => {
                return forceAppRefresh();
            }),
        );
    });

    routeToHomeAfterEnroll$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(enrollUserTOTPSuccess),
            concatMap(() => {
                return [routeTo({ url: ['home'] }), getAllChatTags()];
            }),
        );
    });

    routeToHome$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(getMultiFactorInfoSuccess)),
            this.actions$.pipe(ofType(getProfileSuccess)),
        ]).pipe(
            concatLatestFrom(() => [this.store.select(authenticationFeature.selectIsEnrolledWithTOTP)]),
            filter(([, isEnrolledWithTotp]) => isEnrolledWithTotp),
            concatMap(() => {
                return [routeTo({ url: ['agent-chat'] }), getAllChatTags()];
            }),
        );
    });

    routeToEnroll$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(getMultiFactorInfoSuccess)),
            this.actions$.pipe(ofType(getProfileSuccess)),
        ]).pipe(
            concatLatestFrom(() => [this.store.select(authenticationFeature.selectIsEnrolledWithTOTP)]),
            filter(([, isEnrolledWithTotp]) => !isEnrolledWithTotp),
            map(() => {
                return routeTo({ url: ['mfa-setup'] });
            }),
        );
    });
}
