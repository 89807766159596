import { selectBreeds } from '@frontend/data-access/contentful';
import { createSelector } from '@ngrx/store';
import { getBreedInfo } from '../data-access/client-info/client-info.utils';
import { supportPortalClientInfoFeature } from '../data-access/client-info/client-info.reducer';
import { differenceInWeeks } from 'date-fns';

export const selectBreedInfo = createSelector(
    supportPortalClientInfoFeature.selectClientInfoState,
    selectBreeds,
    (clientInfo, breeds) => {
        return {
            breed: getBreedInfo(clientInfo, breeds)?.name,
            breedType: getBreedInfo(clientInfo, breeds)?.breedGroup.name,
        };
    },
);

export const selectDogAge = createSelector(supportPortalClientInfoFeature.selectDateOfBirth, (dateOfBirth) => {
    let birthDate = new Date();
    if (dateOfBirth) {
        birthDate = new Date(dateOfBirth);
    }
    return differenceInWeeks(new Date(), birthDate);
});

export const selectWeeksUntilArrival = createSelector(
    supportPortalClientInfoFeature.selectDateOfArrival,
    (dateOfArrival) => {
        return dateOfArrival ? differenceInWeeks(new Date(dateOfArrival), new Date()) : undefined;
    },
);
