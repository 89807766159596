import { Component, inject } from '@angular/core';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { ChatTagsComponent } from '../chat-tags/chat-tags.component';
import { chatFeature, removeTrainingCoachFromChat } from '@frontend/data-access/chat';
import { selectBreedInfo, selectDogAge, selectWeeksUntilArrival } from './user-information-sidebar.selectors';
import { supportPortalClientInfoFeature } from '../data-access/client-info/client-info.reducer';

@Component({
    selector: 'app-user-information-sidebar',
    templateUrl: './user-information-sidebar.component.html',
    styleUrl: './user-information-sidebar.component.scss',
    imports: [IonContent, ChatTagsComponent, IonButton],
})
export class UserInformationSidebarComponent {
    private readonly store = inject(Store);

    public breedInfo = this.store.selectSignal(selectBreedInfo);
    public age = this.store.selectSignal(selectDogAge);
    public name = this.store.selectSignal(supportPortalClientInfoFeature.selectName);
    public ownerName = this.store.selectSignal(supportPortalClientInfoFeature.selectOwnerName);
    public gender = this.store.selectSignal(supportPortalClientInfoFeature.selectGender);
    public hasArrived = this.store.selectSignal(supportPortalClientInfoFeature.selectHasArrived);
    public countryCode = this.store.selectSignal(supportPortalClientInfoFeature.selectCountryCode);
    public weeksUntilArrival = this.store.selectSignal(selectWeeksUntilArrival);
    public activeChannel = this.store.selectSignal(chatFeature.selectActiveChannel);

    public onEndChat(channelId: string): void {
        this.store.dispatch(removeTrainingCoachFromChat({ channelId }));
    }
}
