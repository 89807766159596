import { inject, Injectable } from '@angular/core';
import { DefaultSystemBrowserOptions, DefaultWebViewOptions, InAppBrowser } from '@capacitor/inappbrowser';
import { WINDOW } from '@frontend/configuration';
import { isCountryCodeNorthAmerica } from '@frontend/data-access/geo-location';
import { NavController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isEmpty } from '@shared/utils/typescript';
import { filter, tap } from 'rxjs/operators';
import {
    REST_OF_WORLD_COOKIE_NOTICE,
    REST_OF_WORLD_PRIVACY,
    REST_OF_WORLD_TERMS,
    US_COOKIE_NOTICE,
    US_PRIVACY,
    US_TERMS,
} from '../constants/router.constants';
import {
    forceAppRefresh,
    openCookieNotice,
    openPrivacy,
    openTailsUrl,
    openTerms,
    openUrl,
    routeTo,
    routeToPrevious,
} from './router.actions';

@Injectable()
export class RouterEffects {
    private readonly actions$ = inject(Actions);
    private readonly navController = inject(NavController);
    private readonly window = inject<Window>(WINDOW);

    // TODO: NBSon - see if we can change the naming of some of these actions/events
    // also see if we can sort out the isAnimated/props to just use the NavigationOptions
    routeTo$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routeTo),
                tap(({ url, extras, isAnimated }) => {
                    void this.navController.navigateForward(url, { ...extras, animated: isAnimated ?? false });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    routeToPrevious$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routeToPrevious),
                filter(({ url }) => isEmpty(url)),
                tap(({ isAnimated }) => {
                    void this.navController.back({ animated: isAnimated ?? true });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    routeToBackwards$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routeToPrevious),
                filter(({ url }) => !isEmpty(url)),
                tap(({ url, extras, isAnimated }) => {
                    void this.navController.navigateBack(url!, { ...extras, animated: isAnimated ?? false });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    // TODO - JL - Remove me and migrate tails over to using openUrl when they fix their website
    openTailsUrl$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openTailsUrl),
                tap(async ({ url }) => {
                    await InAppBrowser.openInSystemBrowser({
                        url,
                        options: DefaultSystemBrowserOptions,
                    });
                }),
            );
        },
        { dispatch: false },
    );

    openUrl$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openUrl),
                tap(async ({ url }) => {
                    await InAppBrowser.openInWebView({
                        url,
                        options: DefaultWebViewOptions,
                    });
                }),
            );
        },
        { dispatch: false },
    );

    forceAppRefresh$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(forceAppRefresh),
                tap(() => {
                    this.window.location.href = new URL(this.window.location.href).origin;
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openPrivacy$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openPrivacy),
                tap(async ({ countryCode }) => {
                    if (isCountryCodeNorthAmerica(countryCode)) {
                        await InAppBrowser.openInWebView({
                            url: US_PRIVACY,
                            options: DefaultWebViewOptions,
                        });
                    } else {
                        await InAppBrowser.openInWebView({
                            url: REST_OF_WORLD_PRIVACY,
                            options: DefaultWebViewOptions,
                        });
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openTerms$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openTerms),
                tap(async ({ countryCode }) => {
                    if (isCountryCodeNorthAmerica(countryCode)) {
                        await InAppBrowser.openInWebView({
                            url: US_TERMS,
                            options: DefaultWebViewOptions,
                        });
                    } else {
                        await InAppBrowser.openInWebView({
                            url: REST_OF_WORLD_TERMS,
                            options: DefaultWebViewOptions,
                        });
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openCookieNotice$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openCookieNotice),
                tap(async ({ countryCode }) => {
                    if (isCountryCodeNorthAmerica(countryCode)) {
                        await InAppBrowser.openInWebView({
                            url: US_COOKIE_NOTICE,
                            options: DefaultWebViewOptions,
                        });
                    } else {
                        await InAppBrowser.openInWebView({
                            url: REST_OF_WORLD_COOKIE_NOTICE,
                            options: DefaultWebViewOptions,
                        });
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
