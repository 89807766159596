import { createAction, props } from '@ngrx/store';
import { ClientInfo } from './client-info.model';

export const getClientInfo = createAction('[Agent Chat] Get Client Info', props<{ userId: string }>());

export const getClientInfoSuccess = createAction(
    '[Agent Chat] Get Client Info Success',
    props<{ clientInfo: ClientInfo }>(),
);

export const getClientInfoFailure = createAction('[Agent Chat] Get Client Info Failure', props<{ error: Error }>());

export const mfaPageLoaded = createAction('[Auth] MFA Page Loaded');
