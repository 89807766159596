import { Component } from '@angular/core';
import { IonSkeletonText } from '@ionic/angular/standalone';

@Component({
    selector: 'app-chat-channel-list-skeleton',
    templateUrl: './chat-channel-list-skeleton.component.html',
    styleUrl: './chat-channel-list-skeleton.component.scss',
    imports: [IonSkeletonText],
})
export class ChatChannelListSkeletonComponent {
    numberOfSkeletonMessages = [1, 2, 3, 4, 5, 6, 7, 8, 9];
}
