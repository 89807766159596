import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonButton, IonContent, IonInput } from '@ionic/angular/standalone';
import { QRCodeModule } from 'angularx-qrcode';
import { Color } from '@shared/utils/typescript';
import { Store } from '@ngrx/store';
import { authenticationFeature, enrollUserTOTP, logout } from '@frontend/data-access/user/authentication';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';

@Component({
    selector: 'app-mfa-entry',
    templateUrl: './mfa-entry.page.html',
    styleUrls: ['./mfa-entry.page.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, IonContent, QRCodeModule, IonButton, IonInput, InvalidInputErrorMessageComponent],
})
export class MfaEntryPageComponent {
    verificationForm = new FormGroup({
        code: new FormControl('', { validators: Validators.required, nonNullable: true }),
    });

    private readonly store = inject(Store);
    protected readonly Color = Color;

    public totpError = this.store.selectSignal(authenticationFeature.selectTotpSecretError);

    onClick() {
        this.store.dispatch(enrollUserTOTP({ verificationCode: this.verificationForm.controls.code.value }));
    }

    public logoutAgent() {
        this.store.dispatch(logout());
    }
}
