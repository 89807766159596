import { Component, inject, OnInit } from '@angular/core';
import { initSupportPortalSentry } from '@frontend/data-access/sentry';
import { initializeAuthenticationService } from '@frontend/data-access/user/authentication';
import { IonContent, IonRouterOutlet } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    imports: [IonRouterOutlet, IonContent],
})
export class AppComponent implements OnInit {
    private readonly store = inject(Store);

    public ngOnInit(): void {
        this.store.dispatch(initSupportPortalSentry());
        this.store.dispatch(initializeAuthenticationService());
    }
}
