import { Color } from '@shared/utils/typescript';
import { Asset, Entry, RichTextContent } from 'contentful';
import { ContentfulStep } from './contentful-step.model';

export interface ContentfulCourse {
    id: string;
    title: string;
    rank: number;
    steps: Entry<ContentfulStep>[];
    split: string[];
    introduction: string;
    explanation: RichTextContent;
    color: Color;
    image: Asset;
    foundational: boolean;
    subTitle?: string;
    courseCardSquiggle: string;
}
