import { InternalAgentDto } from '@shared/internal-api-interface';
import { Profile } from './profile.model';

export function mapAgentDtoToProfile(agent: InternalAgentDto): Profile {
    return {
        id: agent.id,
        name: agent.name ?? '',
        email: agent.email ?? '',
        token: agent.userToken ?? '',
    };
}
