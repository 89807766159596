import { AsyncPipe } from '@angular/common';
import { AfterViewInit, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { destroyChat } from '@frontend/data-access/chat';
import { EmojiPickerComponent } from '@frontend/ui/emoji-picker';
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { CustomTemplatesService, EmojiPickerContext, StreamChatModule } from 'stream-chat-angular';
import { AgentHeaderComponent } from '../../agent-header/agent-header.component';
import { UserInformationSidebarComponent } from '../../user-information-sidebar/user-information-sidebar.component';
import { ChatChannelListSkeletonComponent } from '../chat-channel-list/chat-channel-list-skeleton.component';
import { chatPageInitialized } from './store/agent-chat.page.actions';
import { selectChatVm } from './store/agent-chat.page.selectors';

@Component({
    selector: 'app-agent-chat',
    templateUrl: './agent-chat.page.html',
    styleUrl: './agent-chat.page.scss',
    imports: [
        AgentHeaderComponent,
        StreamChatModule,
        ChatChannelListSkeletonComponent,
        UserInformationSidebarComponent,
        EmojiPickerComponent,
        AsyncPipe,
        IonContent,
        IonRefresher,
        IonRefresherContent,
    ],
})
export class AgentChatPageComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly store = inject(Store);
    private readonly customTemplatesService = inject(CustomTemplatesService);

    @ViewChild('emojiPickerTemplate')
    private emojiPickerTemplate!: TemplateRef<EmojiPickerContext>;

    isMenuOpen = true;
    isSidebarOpen = false;

    vm$ = this.store.select(selectChatVm);

    ngOnInit() {
        this.store.dispatch(chatPageInitialized());
    }

    ngAfterViewInit() {
        this.customTemplatesService.emojiPickerTemplate$.next(this.emojiPickerTemplate);
    }

    ngOnDestroy() {
        this.store.dispatch(destroyChat());
    }

    handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setTimeout(() => {
            window.location.reload();
            event.detail.complete();
        }, 1000);
    }
}
