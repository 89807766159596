import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { getClientInfoSuccess } from './client-info.actions';
import { ClientInfo } from './client-info.model';
import { removeTrainingCoachFromChatSuccess } from '@frontend/data-access/chat';

export const initialState: ClientInfo = {
    name: undefined,
    ownerName: undefined,
    dateOfBirth: undefined,
    gender: undefined,
    dateOfArrival: undefined,
    hasArrived: undefined,
    countryCode: undefined,
    firstDayAtHomeWhenOnboarding: undefined,
    breedId: undefined,
};

export const supportPortalClientInfoFeature = createFeature({
    name: 'clientInfo',
    reducer: createReducer(
        initialState,
        on(
            getClientInfoSuccess,
            (state, action): ClientInfo => ({
                ...state,
                ...action.clientInfo,
            }),
        ),
        on(
            removeTrainingCoachFromChatSuccess,
            (state): ClientInfo => ({
                ...state,
                ...initialState,
            }),
        ),
    ),
    extraSelectors: ({ selectClientInfoState }) => ({
        selectName: createSelector(selectClientInfoState, (clientInfo) => clientInfo.name),
    }),
});
