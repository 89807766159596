@if (vm$ | async; as vm) {
<ion-content [fullscreen]="true" class="page-ion-content ion-text-center doodle" color="harley">
    <div class="agent-reset-password-container">
        <main class="page-content agent-reset-password-content">
            <section>
                <h1 class="app-background">Enter your email</h1>
                <p class="ion-padding-start ion-padding-end">We’ll send you a link to reset your password</p>
            </section>
            <form [formGroup]="requestPasswordResetForm">
                <ion-item>
                    <ion-input
                        class="zz-input"
                        data-test="email-input"
                        type="email"
                        placeholder="Email"
                        [formControlName]="'email'"
                        [tabindex]="1"
                    ></ion-input>
                </ion-item>
                @if (showEmailError()) {
                <ui-invalid-input-error-message
                    errorMessage="Please fill in a valid email"
                ></ui-invalid-input-error-message>
                }
            </form>
            <section>
                <ion-button
                    class="reset-password-button"
                    color="max"
                    size="large"
                    expand="block"
                    [disabled]="!requestPasswordResetForm.valid || vm.isLoading"
                    data-test="send-reset-email-button"
                    (click)="send()"
                >
                    @if (vm.isLoading) {
                    <ion-spinner class="agent-reset-spinner" name="crescent"></ion-spinner>
                    } @else {
                    <span>Reset Password</span>
                    }
                </ion-button>
                @if (vm.shouldShowSuccessMessage) {
                <p>Email sent! Please check your inbox to reset your password.</p>
                }
            </section>
        </main>
    </div>
</ion-content>
}
