import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Observable, Subject } from 'rxjs';
import { ThemeService } from 'stream-chat-angular';

@Component({
    selector: 'ui-emoji-picker',
    templateUrl: './emoji-picker.component.html',
    styleUrl: './emoji-picker.component.scss',
    imports: [AsyncPipe, PickerModule],
})
export class EmojiPickerComponent {
    @Input({ required: true }) emojiInput$!: Subject<string>;

    @ViewChild('container')
    container: ElementRef<HTMLElement> | undefined;

    isOpened = false;
    theme$: Observable<string>;

    constructor() {
        const themeService = inject(ThemeService);

        this.theme$ = themeService.theme$;
    }

    toggled() {
        this.isOpened = !this.isOpened;

        if (this.isOpened) {
            window.addEventListener('click', this.eventHandler);
        } else {
            window.removeEventListener('click', this.eventHandler);
        }
    }

    emojiSelected(event: EmojiEvent) {
        this.emojiInput$.next(event.emoji.native ?? '');
    }

    private eventHandler = (event: Event) => {
        // Watching for outside clicks
        if (!this.container?.nativeElement.contains(event.target as Node)) {
            this.isOpened = false;
            window.removeEventListener('click', this.eventHandler);
        }
    };
}
