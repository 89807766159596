import { Color } from '@shared/utils/typescript';

export interface InternalSeasonalTopicDto {
    id: string;
    title: string;
    image: string;
    backgroundColour: Color;
    topic: string;
    startDate: string;
    endDate: string;
}
