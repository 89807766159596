import { inject, Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    getMultiFactorResolver,
    GoogleAuthProvider,
    idToken,
    indexedDBLocalPersistence,
    initializeAuth,
    multiFactor,
    MultiFactorError,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithCredential,
    signInWithEmailAndPassword,
    signOut,
    TotpMultiFactorGenerator,
    TotpSecret as FirebaseTotpSecret,
    user,
} from '@angular/fire/auth';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Store } from '@ngrx/store';
import { BehaviorSubject, from, lastValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { logInWithAppleOptions } from '../consts/login-with-apple-options.const';
import { watchForLoginStateChange } from '../store/authentication.actions';
import { mapFireBaseUserToUser, mapFireBaseUserWithAdditionalUserInfoToUser } from '../utils/authentication.map';
import { AuthenticationService } from './authentication.service';
import { TotpSecret, User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class FirebaseAuthenticationService extends AuthenticationService {
    private readonly app = inject(FirebaseApp);
    private readonly store = inject(Store);
    private auth = initializeAuth(this.app, {
        persistence: [indexedDBLocalPersistence],
    });

    public token$ = idToken(this.auth);
    public user$: Observable<User | undefined> = user(this.auth).pipe(
        map((user) => {
            if (!user || !user.email) {
                return undefined;
            }

            return {
                id: user.uid,
                email: user.email,
                signInProvider: user.providerData[0].providerId,
            };
        }),
    );

    public isMfaEnrolled$: Observable<boolean> = user(this.auth).pipe(
        map((user) => {
            if (!user || !user.email) {
                return false;
            }

            return !!multiFactor(user).enrolledFactors.length;
        }),
    );

    private totpSecret: FirebaseTotpSecret | undefined;
    private userSubject = new BehaviorSubject<User | undefined>(undefined);

    constructor() {
        super();
        this.user$.subscribe(this.userSubject);
    }

    public get user(): User | undefined {
        return this.userSubject.getValue();
    }

    public initialize(): void {
        this.store.dispatch(watchForLoginStateChange());
    }

    public signUpWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        return from(createUserWithEmailAndPassword(this.auth, email.trim(), password)).pipe(
            switchMap((credential) => of(mapFireBaseUserToUser(credential.user))),
        );
    }

    public loginWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        return from(signInWithEmailAndPassword(this.auth, email.trim(), password)).pipe(
            switchMap((credential) => of(mapFireBaseUserToUser(credential.user))),
        );
    }

    public async loginWithEmailAndPasswordAndTotp(email: string, password: string, code: string) {
        try {
            return await lastValueFrom(this.loginWithEmailAndPassword(email, password));
        } catch (error) {
            if ((error as MultiFactorError).code == 'auth/multi-factor-auth-required') {
                const mfaError = error as MultiFactorError;
                const mfaResolver = getMultiFactorResolver(this.auth, mfaError);

                const totpFactor = mfaResolver.hints.find((factor) => factor.factorId === 'totp');
                const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(totpFactor!.uid, code);

                const credential = await mfaResolver.resolveSignIn(multiFactorAssertion);
                return mapFireBaseUserToUser(credential.user);
            }
            throw error;
        }
    }

    public loginWithGoogle() {
        return from(FirebaseAuthentication.signInWithGoogle()).pipe(
            switchMap((signInResponse) => {
                const credential = GoogleAuthProvider.credential(signInResponse.credential?.idToken);

                // Here, the additionalUserInfo returns isNewUser true for new users
                const additionalUserInfo = signInResponse.additionalUserInfo;

                return from(signInWithCredential(this.auth, credential)).pipe(
                    switchMap((credential) => {
                        // Here, the additionalUserInfo returns isNewUser false for new users, since it's created in the previous step

                        return of(mapFireBaseUserWithAdditionalUserInfoToUser(credential.user, additionalUserInfo!));
                    }),
                );
            }),
        );
    }

    public loginWithApple() {
        // authenticate the user in apple
        return from(SignInWithApple.authorize(logInWithAppleOptions)).pipe(
            switchMap((signInResponse) => {
                // sign the user up in firebase
                const provider = new OAuthProvider('apple.com');
                const credential = provider.credential({ idToken: signInResponse.response.identityToken });
                return from(signInWithCredential(this.auth, credential)).pipe(
                    switchMap((credential) => {
                        // return the user and additional info from Firebase
                        const additionalUserInfo = getAdditionalUserInfo(credential);
                        return of(mapFireBaseUserWithAdditionalUserInfoToUser(credential.user, additionalUserInfo!));
                    }),
                );
            }),
        );
    }

    public logout(): Observable<void> {
        return from(signOut(this.auth));
    }

    public isLoggedIn(): Observable<boolean> {
        return this.user$.pipe(map((user): boolean => !!user));
    }

    public requestPasswordReset(email: string): Observable<void> {
        return from(sendPasswordResetEmail(this.auth, email.trim()));
    }

    public getSignInProvider(): Observable<string> {
        return of(this.auth.currentUser!.providerData[0].providerId);
    }

    public async generateTOTPSecret(): Promise<TotpSecret> {
        if (!this.auth.currentUser) {
            throw new Error('current user missing');
        }

        const multiFactorSession = await multiFactor(this.auth.currentUser).getSession();
        this.totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);

        return {
            key: this.totpSecret.secretKey,
            url: this.totpSecret.generateQrCodeUrl(this.auth.currentUser.email!, 'Zigzag Support Portal'),
        };
    }

    public async enrollUserTOTP(verificationCode: string): Promise<void> {
        if (!this.auth.currentUser) {
            throw new Error('current user missing');
        }

        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
            this.totpSecret!,
            verificationCode,
        );

        return await multiFactor(this.auth.currentUser).enroll(multiFactorAssertion, 'Zigzag Support Portal');
    }
}
